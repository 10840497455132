import React, { useState } from "react"
import DeleteIcon from '@mui/icons-material/Delete'
import messService from "./Message.service"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectMessage } from "./store/message.selector";
import { MessInjectedProps } from "./containers/message.container";
import { getMessageAction } from "./store/message.action";
// import styled from 'styled-components';
import { Backdrop, Checkbox, CircularProgress, InputLabel, ListItemText, MenuItem, Select, styled } from "@mui/material";
import {
  Grid,
  TableContainer,
  Table,
  tableCellClasses,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Box,
  Pagination,
  Stack,
  FormControl,
  TableSortLabel
} from '@mui/material'

import domaineServices from "../Domaine/services/domaine.service";
import userServices from "../Utilisateur/services/utilisateur.service";

interface Option {
  value: string,
  label: string
}
const MessageR : React.FC<MessInjectedProps> = ({ messages, loadMessages }) => {
  const [message, setMessage] = React.useState('');
  const [idMess, setIdMes] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [selectMultiple, setSelectMultiple] = useState <string []>([])
  const [selectMultiple2, setSelectMultiple2] = useState <string []>([])

  const [domaines, setDomaines] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  const [isSendButtomDisabled, setIsSendButtomDisabled] = React.useState(true); 
  const [classStyleSendButton, setClassStyleSendButton] = React.useState<string>("ml-2 px-4 py-2 rounded-full bg-blue-200 text-white font-semibold hover:bg-blue-200 focus:outline-none focus:ring focus:border-blue-200")

  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [pageLastButton, setPageLastButton] = useState<number>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const handleChangeRowsPerPage = (value: number) => setRowsPerPage(value)

const options : Option[ ]= [
  { value: 'Chocolate', label: 'Chocolate' },
  { value: 'Strawberry', label: 'Strawberry' },
  { value: 'Vanilla', label: 'Vanilla' }
];
const options2 : Option[ ]= [
  { value: 'Pink', label: 'Pink' },
  { value: 'White', label: 'White' },
  { value: 'Red', label: 'Red' }
];

  const messa = useSelector(selectMessage)

  const handleMessageChange = async(e:any) => {
   setMessage(e.target.value);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage)
}

  React.useEffect(()=>{
    if (message !== null && message!=='') {
      setIsSendButtomDisabled(false)
      setClassStyleSendButton("ml-2 px-4 py-2 rounded-full bg-blue-500 text-white font-semibold hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300")
    }else{
      setIsSendButtomDisabled(true)
      setClassStyleSendButton("ml-2 px-4 py-2 rounded-full bg-blue-200 text-white font-semibold hover:bg-blue-200 focus:outline-none focus:ring focus:border-blue-200")
    }
  },[message])
  
  const dispatch = useDispatch()
  const listMess = async () =>{
    setLoading(true)
    await messService.getMessage()
      .then((resp:any)=>{
       // console.log("response ici data",resp.data.data)
        dispatch(getMessageAction(resp.data.data))
      })
      .catch(()=>{})
      .finally(()=>setLoading(false))
  }

  const fetchDomaines = async () => {
    // setLoading(true);
    try {
      const response = await domaineServices.get();
      setDomaines(response.data.domaines);
    } catch (error) {
      console.error("Erreur lors de la récupération des domaines:", error);
    } finally {
      // setLoading(false);
    }
  };

  const fetchUsers = async () => {
    // setLoading(true);
    try {
      const response = await userServices.getAll(1);
      setUsers(response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs:", error);
    } finally {
      // setLoading(false);
    }
  };
  

  React.useEffect(()=>{
    // listMess()
  // eslint-disable-next-line react-hooks/exhaustive-deps
    
    fetchDomaines();
    fetchUsers();
  },[])

  const listMessPagination = async () =>{
    setLoading(true)
    await messService.getMessagePagination(page, rowsPerPage)
      .then((resp:any)=>{
       // console.log("response ici data",resp.data.data)
       setTotalPage(resp.data.totalPage)
        dispatch(getMessageAction(resp.data.data))
        // setPageLastButton(Math.ceil(createurs.length / rowsPerPage))
        setRowsPerPage(rowsPerPage)
      })
      .catch(()=>{})
      .finally(()=>setLoading(false))
  }

  React.useEffect(()=>{
    listMessPagination()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
    
  //   fetchDomaines();
  //   fetchUsers();
  },[page, rowsPerPage, totalPage])

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };
  React.useEffect(()=>{
    scrollToBottom()
  },[messa]);
  const notifierUser = async()=>{
    if(idMess.length>0){
      await messService.getMessageID(idMess)
    }
  }
  React.useEffect(()=>{
    notifierUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[idMess])


  const handleMessageSubmit = async(e:any) => {
    e.preventDefault();
    if (message.trim() !== '') {
      try {
        const filteredDomaines = domaines.filter(domaine => selectMultiple.includes(domaine.name));
        const idfilteredDomaines = filteredDomaines.map(domaine => domaine._id);
        const filteredUsers = users.filter(user => selectMultiple2.includes(user.lastname));
        const idfilteredUsers = filteredUsers.map(user => user._id);
        const data ={
          content:message,
          domaines: idfilteredDomaines,
          // domaines: filteredDomaines,
          users: idfilteredUsers
          // users: filteredUsers
        }
        setLoading(true)
        setPage(totalPage)
        const resp=await messService.CreateMesage(data)
        setLoading(false)
        dispatch(getMessageAction([...messa,resp.data.data]))
        setIdMes(resp.data.data._id)
        setMessage('')
      }
      catch (e) {
          console.log(e)
          setLoading(false)
      }
    }
  };

  const handleSelectedChange = (e: any) =>{
    setSelectMultiple(e.target.value)
  }
  const handleSelectedChange2 = (e: any) =>{
    setSelectMultiple2(e.target.value)
  }

    return (
        <div className="h-[90%] flex flex-col">
            <div className="flex-1 ">
                <div className="w-full flex flex-col">
                {messa && messa.map((msg: any, index: number) => (
                    <div key={index} className="flex  justify-between p-2 overflow-y-auto">
                      <div className="flex flex-row bg-white-300 justify-between flex-1">
                          <div className=" text-black rounded-md p-2">
                          {msg.content}
                          </div>
                          <div className="mr-9">
                            <DeleteIcon
                                color="error"
                                onClick={() => {}}
                                className="cursor-pointer"
                            />
                          </div>
                      </div>
                    </div>
                ))}
                </div>
            </div>
            {messa.length > 0 && (
                <Box sx={{ display: 'flex', mt: 5 }}>
                    <Grid container alignItems="center" justifyContent="end" gap={1}>
                        <Stack spacing={2}>
                            <Pagination
                                showFirstButton={page === 1}
                                showLastButton={page === pageLastButton}
                                count={totalPage}
                                page={page}
                                onChange={handleChangePage}
                                color="primary"
                                shape="rounded"
                            />
                        </Stack>

                        <FormControl sx={{ m: 1, minWidth: 120, background: 'white' }} size="small">
                            <Select
                                // value={rowsPerPage}
                                value={rowsPerPage}
                                onChange={e => handleChangeRowsPerPage(+e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={'5'}>5 / page</MenuItem>
                                <MenuItem value={'10'}>10 / page</MenuItem>
                                <MenuItem value={'25'}>25 / page</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Box>
            )}
            <div className="p-4">
            <InputLabel id="multiple-select-label">Select Options</InputLabel>
                <Select
                  labelId="multiple-select-label"
                  multiple
                  value={selectMultiple}
                  onChange={(e) => {handleSelectedChange(e)}}
                  renderValue={(selected) => selected.join(', ')}
                  style={{width : '45%' , borderRadius : "12px"}}
                >
                  <em>Domaine</em>
                  {domaines.map((domaine) => (
                    <MenuItem key={domaine._id} value={domaine.name}>
                      <Checkbox checked={selectMultiple.indexOf(domaine.name) > -1} />
                      <ListItemText primary={domaine.name} />
                    </MenuItem>
                  ))}

                   {/* {options.map((option) => (
                    <MenuItem key={option.value} value={option.value} >
                      <Checkbox checked={selectMultiple.indexOf(option.value) > -1}/>
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))} */}
                </Select>
                <Select
                  
                  labelId="multiple-select-label"
                  placeholder="Selectionner domaine "
                  multiple
                  value={selectMultiple2}
                  onChange={(e) => {handleSelectedChange2(e)}}
                  renderValue={(selected) => selected.join(', ')}
                  style={{width : '45%' , borderRadius : "12px", marginLeft: "20px"}}
                 >
                    <em>Utilisateurs</em>
                    {users.map((user) => (
                    <MenuItem key={user._id} value={user.lastname} >
                      <Checkbox checked={selectMultiple2.indexOf(user.lastname) > -1}/>
                      <ListItemText primary={user.firstname+" "+user.lastname} />
                    </MenuItem>
                  ))}
                   {/* {options2.map((option) => (
                    <MenuItem key={option.value} value={option.value} >
                      <Checkbox checked={selectMultiple2.indexOf(option.value) > -1}/>
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))} */}
                 </Select>
            </div>
                 
            
            <form onSubmit={handleMessageSubmit} className="flex justify-between items-center p-4">
                
                <input
                  type="text"
                  value={message}
                  onChange={handleMessageChange}
                  placeholder="Entrez votre message..."
                  className="flex-1 px-4 py-2 rounded-full bg-gray-200 focus:outline-none focus:ring focus:border-blue-300"
                />
                <button
                  type="submit"
                  // className="ml-2 px-4 py-2 rounded-full bg-blue-500 text-white font-semibold hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                  className={classStyleSendButton}
                  disabled={isSendButtomDisabled}
                >
                Envoyer
                </button>
            </form>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress />
            </Backdrop>
        </div>
    )
}
export default MessageR